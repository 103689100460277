// components/Home.js
import { Heading } from '@aws-amplify/ui-react';
import { useEffect } from 'react';
export function Home() {
  useEffect(() => {
    document.title = 'EAK Albums';
  }, []);
  return (
    <Heading level={3}>
      Please login or signup for an account to view photos.
      <br/><br/>
      If you have a login but still can't see any photos, please let me (Dave) know so I can grant you access.
    </Heading>
  );
}