import {
  useAuthenticator, Heading, Image,
  View, Button,
  useTheme
  } from '@aws-amplify/ui-react';
  import { API, Storage } from 'aws-amplify';
  import { useParams, useNavigate } from "react-router-dom";
  import React, { useEffect, useState } from 'react';
  
  export function ProtectedViewPhoto() {
  const { route } = useAuthenticator((context) => [context.route]);
  
  const message =
  route === 'authenticated' ? ViewPhoto() : 'Loading...';
  return <Heading level={1}>{message}</Heading>;
  }
  
  function ViewPhoto() {
  const navigate = useNavigate();
  
  const params = useParams();
  const [photo, setPhoto] = useState([]);
  
  async function loadImage() {
  const image = await Storage.get(params.id);
  setPhoto(image);
  };
  
  useEffect(() => {
  loadImage();
  }, []);
  
  return (
  <View><Button onClick={() => navigate(-1)}>Back</Button>
  <Image
  src={photo}
  style={{
  width: '100%',
  height: 'auto',
  maxHeight: '100vh',
  objectFit: 'contain'
  }}
  ></Image>
  </View>
  );
  }