// components/ProtectSecond.js
import {
  useAuthenticator, Heading, Card,
  Image,
  View, Link,
  Flex,
  Badge,
  Text,
  Button,
  useTheme, Collection, Divider
} from '@aws-amplify/ui-react';
import { API, Storage } from 'aws-amplify';
import { useParams, useNavigate } from "react-router-dom";
import { getPhotoPostsByPhotoAlbumId, getPhotoAlbum } from '../graphql/customQueries';
import { deletePhotoPost as deletePhotoPostMutation } from '../graphql/mutations';
import React, { useEffect, useState } from 'react';



export function ProtectedViewPhotoAlbum() {
  const { route } = useAuthenticator((context) => [context.route]);

  

  const message =
    route === 'authenticated' ? ViewPhotoAlbum() : 'Loading...';






  return <Heading level={1}>{message}</Heading>;
}



function ViewPhotoAlbum() {
  const { tokens } = useTheme();
  const [photoAlbum, setPhotoAlbum] = useState([]);
  const [photoPosts, setPhotoPosts] = useState([]);
  const params = useParams();
  const navigate = useNavigate();


  //alert(params.id);

  async function fetchPhotoPosts() {
    if (typeof params.id !== 'undefined') {
      const apiData = await API.graphql({ query: getPhotoPostsByPhotoAlbumId, variables: { id: params.id } });
      const photoAlbumsFromAPI = apiData.data.listPhotoPosts.items;
      await Promise.all(photoAlbumsFromAPI.map(async photoAlbum => {
        if (photoAlbum.photoImageName) {
          const image = await Storage.get(photoAlbum.photoImageName);
          photoAlbum.photoImageName = image;
        }
        return photoAlbum;
      })) 
      setPhotoPosts(apiData.data.listPhotoPosts.items);
      //alert("in here!");
    }
  }

  async function deletePhotoPost({ id }) {
    const newPhotoPostsArray = photoPosts.filter(photoPost => photoPost.id !== id);
    const deletedPhotoPostsArray = photoPosts.filter(photoPost => photoPost.id === id);
    setPhotoPosts(newPhotoPostsArray);
    await API.graphql({ query: deletePhotoPostMutation, variables: { input: { id } }});
    //alert(deletedNotesArray[0].imageName);
    await Storage.remove(deletedPhotoPostsArray[0].imageName);
  }

  async function fetchPhotoAlbum() {
    if (typeof params.id !== 'undefined') {
      const apiData = await API.graphql({ query: getPhotoAlbum, variables: { id: params.id } });
      setPhotoAlbum(apiData.data.getPhotoAlbum);
      //alert("in here!");
    }
  }

  useEffect(() => {
    fetchPhotoPosts();
    fetchPhotoAlbum();
  }, []);

  

  function renderViewButton(id, numOfChildrenAlbums) {
    if (numOfChildrenAlbums > 0)
      return <Link href={`/photoAlbums/${id}`}><Button variation="primary">View Album</Button></Link>
    else
      return <Link href={`/photoAlbum/${id}`}><Button variation="primary">View Photos</Button></Link>
  }

  function photoAlbumCard(id, name, description, albumImage) {
    return (
      <Card key={id}>
        <Flex direction="row" alignItems="flex-start">
          <Image
            alt={name}
            src={albumImage}
            width="200px"
            height="200px"
          />
          <Flex
            direction="column"
            alignItems="flex-start"
            gap={tokens.space.xs}
          >

            <Heading level={5}>
              {name}
            </Heading>

            <Text as="span">
              {description}
            </Text>
          </Flex>
        </Flex>
      </Card>
    )
  }

  function renderImageCollection(items)
  {
    return(
    <Collection
    items={items}
    type="list"
    direction="row"
    gap="20px"
    wrap="nowrap"
  >
    {(item, index) =>  ( 
      <Card
        key={index}
        borderRadius="medium"
        maxWidth="20rem"
        variation="outlined"
      >
        <Image
          src={item.photoImageName}
          alt={item.title}
        />
        <View padding="xs">
          <Flex>
            {/*item.badges.map((badge) => (
              <Badge
                key={badge}
                backgroundColor={
                  badge === 'Waterfront' ? 'blue.40' 
                  : badge === 'Mountain' ? 'green.40' : 'yellow.40'}
              >
                {badge}
              </Badge>
                ))*/}
          </Flex>
          <Divider padding="xs" />
          <Heading padding="medium">{item.title}</Heading>
          <Button variation="primary" isFullWidth>
            View Image
          </Button>
          <Button variation="primary" isFullWidth onClick={() => deletePhotoPost(item)}>Delete Image</Button>
        </View>
      </Card>
    )}
  </Collection>)
  }

  return (<View>
    <View><Button onClick={() => navigate(`/photoAlbums/${photoAlbum.photoAlbumParentPhotoAlbumId}`)}>Back to Previous Album</Button><Button onClick={() => navigate(`/addPhotoToPhotoAlbum/${params.id}`)}>Add Photo to Album</Button></View>

    <View
      backgroundColor={tokens.colors.background.secondary}
      padding={tokens.space.medium}
    >
      {/*photoAlbums.map(photoAlbum => (photoAlbumCard(photoAlbum.id, photoAlbum.title, photoAlbum.title, photoAlbum.photoImageName)))*/}
      {renderImageCollection(photoPosts)}
    </View></View>
  );

}