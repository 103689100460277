import React, { useState, useEffect,  useRef } from 'react';
import styles from '../css/styles.module.css';
import { API, Storage } from 'aws-amplify';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

function FullscreenView({ imagesLocations, index, onClose }) {
  let location = useLocation();
  let navigate = useNavigate();
  let params = useParams();
  const rootElement = useRef(null);

  const [currentIndex, setCurrentIndex] = useState(index);
  const [fullImage, setFullImage] = useState([]);
  useEffect(() => {
    getFullImage(imagesLocations[currentIndex].photoImageName);
    rootElement.current.focus();
    console.log("useEffect()");
  }, [currentIndex]);

  function handleKeyDown(event) {
    event.preventDefault();

    if (event.key === "ArrowLeft" || event.key === "ArrowUp" || event.key === "a" || event.key === "A") {
      // Left arrow key pressed
      handleLeftArrowClick();
    } else if (event.key === "ArrowRight" || event.key === "ArrowDown" || event.key === "d" || event.key === "D") {
      // Right arrow key pressed
      handleRightArrowClick();
    } else if (event.key === "Escape")
    {
      // Escape key pressed
      handleCloseButtonClick();
    }
  }

  async function getFullImage(id) {
    setFullImage();
    const image = await Storage.get(id);
    setFullImage(image);
  }

  function handleCloseButtonClick() {
    if (onClose) {
      onClose();
    }
  }

  function handleLeftArrowClick() {
    if (parseInt(currentIndex) > 0) {
      setCurrentIndex(parseInt(currentIndex) - 1);
    }
  }

  function handleRightArrowClick() {
    if (parseInt(currentIndex) < imagesLocations.length - 1) {
      setCurrentIndex(parseInt(currentIndex) + 1);
    }
  }


  return (
    <div ref={rootElement} className={styles.fullscreenmode} onKeyDown={handleKeyDown} tabIndex={-1}>
      {/* Close button element */}
      <div className={styles.closeButton} onClick={handleCloseButtonClick}>
        Close
      </div>
      {/* Left arrow element */}
      <div className={`${styles.arrow} ${styles.leftArrow}`} onClick={handleLeftArrowClick}>
        &lsaquo;
      </div>
      {/* Image element */}
      {fullImage &&
      <img src={fullImage} alt="Fullscreen image" onClick={handleCloseButtonClick} />}
      {/* Right arrow element */}
      <div className={`${styles.arrow} ${styles.rightArrow}`} onClick={handleRightArrowClick}>
        &rsaquo;
      </div>
      <div className={styles.imageTitle}>{imagesLocations[currentIndex].title}</div>
    </div>
  );
}



export default FullscreenView;
