import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuthenticator, Button, Heading } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import '../css/Layout.css';

export function Layout() {
  const { route, signOut } = useAuthenticator((context) => [
    context.route,
    context.signOut,
  ]);
  const navigate = useNavigate();
  const [isAdminUser, setIsAdminUser] = useState(false);
  const [isFamilyPhotoUser, setIsFamilyPhotoUser] = useState(false);

  useEffect(() => {
    async function checkIsAdminUser() {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        if (currentUser && currentUser.signInUserSession.accessToken.payload['cognito:groups'].includes('Admin')) {
          setIsAdminUser(true);
        }
        else {
          setIsAdminUser(false);
        }
      }
      catch (err) {
        setIsAdminUser(false);
      }
    }
    async function checkIsFamilyPhotoUser() {
      try
      {
      const currentUser = await Auth.currentAuthenticatedUser();
      if (currentUser && currentUser.signInUserSession.accessToken.payload['cognito:groups'].includes('FamilyPhotos')) {
        setIsFamilyPhotoUser(true);
      }
      else {
        setIsFamilyPhotoUser(false);
      }
    }
    catch(err)
    {
      setIsFamilyPhotoUser(false);
    }
    }
    checkIsAdminUser();
    checkIsFamilyPhotoUser();
  }, [route]);


  function logOut() {
    signOut();
    navigate('/login');
  }
  return (
    <>
      <nav>
        <Heading level={1}>EAK Albums</Heading>
        <Button onClick={() => navigate('/')}>Home</Button>
        {route === 'authenticated' && isAdminUser && (
          <Button onClick={() => navigate('/createPhotoAlbum')}>
            Create Photo Album
          </Button>
        )}
        {route === 'authenticated' && (isFamilyPhotoUser || isAdminUser) && (
          <Button onClick={() => navigate(`./photoAlbums/${'home'}`)}>
            Photo Albums
          </Button>
        )}
        {route !== 'authenticated' ? (
          <Button onClick={() => navigate('/login')}>Login</Button>
        ) : (
          <Button onClick={() => logOut()}>Logout</Button>
        )}
      </nav>

      <Outlet />
    </>
  );
}