import { useAuthenticator, View, Grid, Card, SelectField } from '@aws-amplify/ui-react';
import React, { useEffect, useState } from 'react';
import { API, Storage } from 'aws-amplify';
import { useParams } from "react-router-dom";
import { createPhotoPost as createPhotoPostMutation } from '../graphql/customMutations';
import { queryPhotoAlbumById } from '../graphql/customQueries';

export function ProtectedAddPhotoToAlbum() {
  const { route } = useAuthenticator((context) => [context.route]);
  const message =
    route === 'authenticated' ? AddPhotoToAlbum() : 'Loading...';
  return <View level={1}>{message}</View>;
}

const initialFormState = { title: '', photoImageName: '' }
function AddPhotoToAlbum() {
  const [formData, setFormData] = useState(initialFormState);
  const [photoAlbum, setPhotoAlbum] = useState([]);
  const [photos, setPhotos] = useState([{ ...initialFormState }]);
  const params = useParams();

  async function fetchPhotoAlbum() {
    const apiData = await API.graphql({ query: queryPhotoAlbumById, variables: { id: params.id } });
    setPhotoAlbum(apiData.data.getPhotoAlbum);
  }

  useEffect(() => {
    fetchPhotoAlbum();
  }, []);

  function handleChange(e, index) {
    const { name, value } = e.target;
    const list = [...photos];
    list[index][name] = value;
    setPhotos(list);
  }

  function handleDrop(e) {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    const list = [...photos];
    for (const file of files) {
      const fileNameWithoutExtension = file.name.split('.').slice(0, -1).join('.');
      const emptyIndex = list.findIndex((photo) => !photo.title && !photo.photoImageName);
      if (emptyIndex >= 0) {
        list[emptyIndex] = {
          title: fileNameWithoutExtension,
          photoImageName: file
        };
      } else {
        list.push({
          title: fileNameWithoutExtension,
          photoImageName: file
        });
      }
    }
    setPhotos(list);
  }
  

  function handleDragOver(e) {
    e.preventDefault();
  }

  function removePhoto(index) {
    const list = [...photos];
    list.splice(index, 1);
    setPhotos(list);
  }

  async function onChangeImageUpload(e, index) {
    if (!e.target.files[0]) return;
    const files = Array.from(e.target.files);
    const list = [...photos];
    await Promise.all(
      files.map(async file => {
        const title = file.name.split('.').slice(0, -1).join('.');
        const newPhoto = { title, photoImageName: file };
        list.push(newPhoto);
      })
    );
    setPhotos(list);
  }

  function generateUUID() {
    var d = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid + '.jpg';
  }

  async function addPhotosToAlbumButton() {
    for (let photo of photos) {
      if (!photo.title || !photo.photoImageName) {
        alert('incomplete input');
        continue;
      }
      const tempImage = photo.photoImageName;
      photo.photoImageName = generateUUID();
      photo.photoAlbumPhotoPostsId = params.id;
      await API.graphql({ query: createPhotoPostMutation, variables
        : { input: photo } });
        if (tempImage) {
          await Storage.put(photo.photoImageName, tempImage);
          photo.photoImageName = "";
        }
      }
      setPhotos([]);
    }
  
    if (photoAlbum.id === "") {
      return (
        <View>Invalid Photo Album ID</View>
      );
    } else {
      return (
        <View>
          <div>Add Photos To Album : {photoAlbum.name}</div>
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Title</th>
                <th>Image</th>
              </tr>
            </thead>
            <tbody>
            {photos.map((photo, i) => (
  <tr key={i}>
    <td>{i + 1}</td>
    <td>
      <input
        name="title"
        placeholder="Photo Name"
        value={photo.title}
        onChange={e => handleChange(e, i)}
      />
    </td>
    <td>
      <input
        type="file"
        name="photoImageName"
        onChange={e => onChangeImageUpload(e, i)}
        multiple
      />
    </td>
    <td>
      <button onClick={() => removePhoto(i)}>X</button>
    </td>
  </tr>
))}

</tbody>

          </table>
          <button onClick={addPhotosToAlbumButton}>Add Photos To Album</button>
        </View>
      );
    }
  }
  