/* eslint-disable */

export const CreatePhotoAlbumCustom = `mutation CreatePhotoAlbumCustom($input: CreatePhotoAlbumInput!) {
    createPhotoAlbum(input: $input) {
      id
      name
    }
  }
  `;

    export const createPhotoPost =  `mutation CreatePhotoPost($input: CreatePhotoPostInput!) {
    createPhotoPost(input: $input) {
      id
    }
  }`;

  export const deletePhotoPost = `mutation DeletePhotoPost($input: DeletePhotoPostInput!) {
    deletePhotoPost(input: $input) {
      id
    }
  }
  `;

  export const deletePhotoAlbum = `mutation DeletePhotoAlbum($input: DeletePhotoAlbumInput!) {
    deletePhotoAlbum(input: $input) {
      id
    }
  }
  `;