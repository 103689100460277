// components/Protected.js
import { useAuthenticator, View, Grid, Card, SelectField } from '@aws-amplify/ui-react';
import React, { useEffect, useState } from 'react';
import { API, Storage } from 'aws-amplify';
import { useParams, useNavigate } from "react-router-dom";  
import { CreatePhotoAlbumCustom as createPhotoAlbumMutation } from '../graphql/customMutations';
import { listPhotoAlbumsCustom } from '../graphql/customQueries';

export function CreatePhotoAlbumProtected() {
  const { route } = useAuthenticator((context) => [context.route]);
  

  const message =
    route === 'authenticated' ? CreatePhotoAlbum() : 'Loading...';
  
    
    

    return <View level={1}>{message}</View>;


  }



  const initialFormState = { name: '', description: '' }


  function CreatePhotoAlbum(){

    const [formData, setFormData] = useState(initialFormState);

    const [photoAlbums, setPhotoAlbums] = useState([]);
    
    const navigate = useNavigate();

/*    function selectPhotoAlbumChild(node) {
      for (let child of node) {
          if(child && Array.isArray(child.parentPhotoAlbum.items) && child.parentPhotoAlbum.items.length > 0)
          {
          selectPhotoAlbumChild(child.parentPhotoAlbum.items[0].parentPhotoAlbum);
        }
      }
  }; */

    async function fetchPhotoAlbums() {
      
      const apiData = await API.graphql({ query: listPhotoAlbumsCustom});
      const photoAlbumsFromAPI = apiData.data.listPhotoAlbums.items;
      
/*
      photoAlbumsFromAPI.map(async photoAlbum => {

        if(photoAlbum.photoAlbumParentPhotoAlbumId)
        {
          photoAlbum.parentName = photoAlbumsFromAPI.find(thisAlbum =>  thisAlbum.id === photoAlbum.photoAlbumParentPhotoAlbumId).name;
          //alert(photoAlbum.parentName);
          photoAlbum.parentName = photoAlbum.parentName + " => ";
        }
        else
        photoAlbum.parentName = "";

          //selectPhotoAlbumChild(photoAlbumsFromAPI);
          //const image = await Storage.get(photoAlbum.photoAlbumCoverImageName);
          //photoAlbum.photoAlbumCoverImageName = image;

        //return photoAlbum;
      })
*/

      setPhotoAlbums(apiData.data.listPhotoAlbums.items);
    }
  
    useEffect(() => {
      fetchPhotoAlbums();
    }, []);

    async function onChangeImageUpload(e) {
      if (!e.target.files[0]) return
      const file = e.target.files[0];
  //    setFormData({ ...formData, image: file.name });
      setFormData({ ...formData, photoAlbumCoverImageName: file });
  //    await Storage.put(file.name, file);
    }

    function generateUUID() {
      var d = new Date().getTime();
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          var r = (d + Math.random()*16)%16 | 0;
          d = Math.floor(d/16);
         return (c=='x' ? r : (r&0x3|0x8)).toString(16);
      });
      return uuid+'.jpg'; };

    async function createPhotoAlbum() {
      if (!formData.name || !formData.photoAlbumCoverImageName) return;
      const tempImage = formData.photoAlbumCoverImageName;
      formData.photoAlbumCoverImageName = generateUUID();
      await API.graphql({ query: createPhotoAlbumMutation, variables: { input: formData } });
      if (formData.photoAlbumCoverImageName) {
        await Storage.put(formData.photoAlbumCoverImageName, tempImage);
        //const image = await Storage.get(formData.image);
        formData.photoAlbumCoverImageName = "";
      }
      //fetchNotes();
      setFormData(initialFormState);
      navigate('/photoAlbums');

    }

    return( 
    <View>
    <div>Create Photo Album</div>
    <Grid>    
      <Card><input
        onChange={e => setFormData({ ...formData, 'name': e.target.value})}
        placeholder="Photo Album Name"
        value={formData.name}
      /></Card>
      <Card>
      <input
        onChange={e => setFormData({ ...formData, 'description': e.target.value})}
        placeholder="Photo Album Description"
        value={formData.description}
      />
      </Card>
      <Card>
<SelectField
  label="Parent Photo Album"
  descriptiveText="Does this belong to another album?"
  onChange={e => setFormData({ ...formData, 'photoAlbumParentPhotoAlbumId': e.target.value})}
>
<option value="">Select one... [optional]</option>
  {photoAlbums.map(photoAlbum => ( <option key={photoAlbum.id} value={photoAlbum.id}>{photoAlbum.name}</option>))}
  {/*photoAlbums.map(photoAlbum => ( photoAlbum.parentPhotoAlbum.items.map(photoAlbum => ( <option key={photoAlbum.id} value={photoAlbum.id}>-{photoAlbum.name}</option>))))}
  {photoAlbums.map(photoAlbum => ( photoAlbum.parentPhotoAlbum.items.map(photoAlbum => ( photoAlbum.parentPhotoAlbum.items.map(photoAlbum => ( <option key={photoAlbum.id} value={photoAlbum.id}>-{photoAlbum.name}</option>))))))}
  {/*photoAlbums.parentPhotoAlbum ? photoAlbums.photoAlbums.map(parentPhotoAlbum => parentPhotoAlbum.items.map(photoAlbum => ( <option key={photoAlbum.id} value={photoAlbum.id}>{photoAlbum.id + " => "+photoAlbum.name}</option>))) : ""}
  {/*alert(photoAlbums)*/}
</SelectField>
      <input
        type="file"
        onChange={onChangeImageUpload}
      /></Card><br/>
      <Card><button onClick={createPhotoAlbum}>Create Photo Album</button></Card>
      </Grid>

    </View>
    )
  } 