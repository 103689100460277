/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPhotoAlbum = /* GraphQL */ `
  mutation CreatePhotoAlbum(
    $input: CreatePhotoAlbumInput!
    $condition: ModelPhotoAlbumConditionInput
  ) {
    createPhotoAlbum(input: $input, condition: $condition) {
      id
      name
      description
      photoAlbumCoverImageName
      parentPhotoAlbum {
        id
        name
        description
        photoAlbumCoverImageName
        createdAt
        updatedAt
        photoAlbumParentPhotoAlbumId
      }
      photoPosts {
        id
        title
        photoImageName
        createdAt
        updatedAt
        photoAlbumPhotoPostsId
      }
      createdAt
      updatedAt
      photoAlbumParentPhotoAlbumId
    }
  }
`;
export const updatePhotoAlbum = /* GraphQL */ `
  mutation UpdatePhotoAlbum(
    $input: UpdatePhotoAlbumInput!
    $condition: ModelPhotoAlbumConditionInput
  ) {
    updatePhotoAlbum(input: $input, condition: $condition) {
      id
      name
      description
      photoAlbumCoverImageName
      parentPhotoAlbum {
        id
        name
        description
        photoAlbumCoverImageName
        createdAt
        updatedAt
        photoAlbumParentPhotoAlbumId
      }
      photoPosts {
        id
        title
        photoImageName
        createdAt
        updatedAt
        photoAlbumPhotoPostsId
      }
      createdAt
      updatedAt
      photoAlbumParentPhotoAlbumId
    }
  }
`;
export const deletePhotoAlbum = /* GraphQL */ `
  mutation DeletePhotoAlbum(
    $input: DeletePhotoAlbumInput!
    $condition: ModelPhotoAlbumConditionInput
  ) {
    deletePhotoAlbum(input: $input, condition: $condition) {
      id
      name
      description
      photoAlbumCoverImageName
      parentPhotoAlbum {
        id
        name
        description
        photoAlbumCoverImageName
        createdAt
        updatedAt
        photoAlbumParentPhotoAlbumId
      }
      photoPosts {
        id
        title
        photoImageName
        createdAt
        updatedAt
        photoAlbumPhotoPostsId
      }
      createdAt
      updatedAt
      photoAlbumParentPhotoAlbumId
    }
  }
`;
export const createPhotoPost = /* GraphQL */ `
  mutation CreatePhotoPost(
    $input: CreatePhotoPostInput!
    $condition: ModelPhotoPostConditionInput
  ) {
    createPhotoPost(input: $input, condition: $condition) {
      id
      title
      photoImageName
      photoAlbum {
        id
        name
        description
        photoAlbumCoverImageName
        createdAt
        updatedAt
        photoAlbumParentPhotoAlbumId
      }
      comments {
        id
        content
        createdAt
        updatedAt
        photoPostCommentsId
      }
      createdAt
      updatedAt
      photoAlbumPhotoPostsId
    }
  }
`;
export const updatePhotoPost = /* GraphQL */ `
  mutation UpdatePhotoPost(
    $input: UpdatePhotoPostInput!
    $condition: ModelPhotoPostConditionInput
  ) {
    updatePhotoPost(input: $input, condition: $condition) {
      id
      title
      photoImageName
      photoAlbum {
        id
        name
        description
        photoAlbumCoverImageName
        createdAt
        updatedAt
        photoAlbumParentPhotoAlbumId
      }
      comments {
        id
        content
        createdAt
        updatedAt
        photoPostCommentsId
      }
      createdAt
      updatedAt
      photoAlbumPhotoPostsId
    }
  }
`;
export const deletePhotoPost = /* GraphQL */ `
  mutation DeletePhotoPost(
    $input: DeletePhotoPostInput!
    $condition: ModelPhotoPostConditionInput
  ) {
    deletePhotoPost(input: $input, condition: $condition) {
      id
      title
      photoImageName
      photoAlbum {
        id
        name
        description
        photoAlbumCoverImageName
        createdAt
        updatedAt
        photoAlbumParentPhotoAlbumId
      }
      comments {
        id
        content
        createdAt
        updatedAt
        photoPostCommentsId
      }
      createdAt
      updatedAt
      photoAlbumPhotoPostsId
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      photoPost {
        id
        title
        photoImageName
        createdAt
        updatedAt
        photoAlbumPhotoPostsId
      }
      content
      createdAt
      updatedAt
      photoPostCommentsId
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      photoPost {
        id
        title
        photoImageName
        createdAt
        updatedAt
        photoAlbumPhotoPostsId
      }
      content
      createdAt
      updatedAt
      photoPostCommentsId
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      photoPost {
        id
        title
        photoImageName
        createdAt
        updatedAt
        photoAlbumPhotoPostsId
      }
      content
      createdAt
      updatedAt
      photoPostCommentsId
    }
  }
`;
