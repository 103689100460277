//App.js
import { Authenticator } from '@aws-amplify/ui-react';

import { CreatePhotoAlbumProtected } from './components/CreatePhotoAlbumProtected';
import { ProtectedAddPhotoToAlbum } from './components/ProtectedAddPhotoToAlbum'
import { ProtectedViewPhotoAlbum } from './components/ViewPhotos';
import { RequireAuth } from './RequireAuth';
import { Login } from './components/Login';
import { ProtectedSecond } from './components/ViewPhotoAlbums';
import { Home } from './components/Home';
import { Layout } from './components/Layout';
import { ProtectedViewPhoto } from './components/ViewPhotoProtected';



import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './App.css';

function MyRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} /> 
          <Route
            path="/createPhotoAlbum"
            element={
              <RequireAuth>
                <CreatePhotoAlbumProtected />
              </RequireAuth>
            }
          />
          <Route path="/viewPhoto/:id" element={  <RequireAuth>
                <ProtectedViewPhoto />
              </RequireAuth>}/>
          <Route
            path="/photoAlbums"
            element={
              <RequireAuth>
                <ProtectedSecond />
              </RequireAuth>
            }
          />
                    <Route
            path="/addPhotoToPhotoAlbum/:id"
            element={
              <RequireAuth>
                <ProtectedAddPhotoToAlbum />
              </RequireAuth>
            }
          />
          <Route
            path="/photoAlbum/:id"
            element={
              <RequireAuth>
                <ProtectedViewPhotoAlbum />
              </RequireAuth>
            }
          />
          
                    <Route
            path="/photoAlbums/:id"
            element={
              <RequireAuth>
                <ProtectedSecond />
              </RequireAuth>
            }
          />
          <Route path="/login" element={<Login />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function App() {
  return (
    <Authenticator.Provider>
      <MyRoutes />
    </Authenticator.Provider>
  );
}

export default App;